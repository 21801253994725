import { createContext, useContext, useEffect, useState } from 'react'
import { errorMessage } from '../utils/message'
import { useAlert } from '@blaumaus/react-alert'
import Cookies from 'js-cookie'
import { sysMsgFlag } from '../config/app'
import { storage } from '../utils/storage'
import { activate, login, logout } from '../services/auth'

/**
 * Context provider for user authentication.
 *
 */

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
    const [loggedIn, setLoggedIn] = useState(false)
    const [user, setUser] = useState({})
    
    const alert = useAlert()

    const session = user => {
        storage.setUser(user)

        setLoggedIn(true)
        setUser(user)
    }

	const signIn = async(data, onComplete) => {
		const email = data.email, password = data.password
		
        await login({ email, password })
            .then(({data}) => session(data))
            .catch(err => alert.error(errorMessage(err)))
        
        if (onComplete) {
            onComplete()
        }
    }

    const signOut = async () => {
        // try {
            // await logout(user.accessToken)

            setLoggedIn(false)
            setUser({})
			storage.clearUser()
			storage.setLogoutEvent()
            Cookies.remove(sysMsgFlag)

            await logout(user.accessToken)
        // } catch (err) {
        //     alert.error(errorMessage(err))
        // }
    }

    const activateAccount = async(code, data, onSuccess) => {
        try {
            const resp = await activate(code, data)
            session(resp.data)
            onSuccess()
        } catch (err) {
            alert.error(errorMessage(err))   
        }
    }

    const editAccount = (data) => {
        console.log(data)
    }

	const resetPassword = (data) => {
        console.log(data)
    }

    useEffect(() => {
        const auth_user = storage.getUser()

        if (auth_user) {
            setLoggedIn(true)
            setUser(auth_user)
        }
    }, [])

    return (
        <AuthContext.Provider value={{
            user,
            loggedIn,
            setSession: user => session(user),
            setLoggedIn,
            signIn,
            signOut,
            activateAccount,
            editAccount,
            resetPassword
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
	const ctx = useContext(AuthContext)

	if (!ctx) {
		throw new Error("useAuth must be used within the AuthProvider")
	}

	return ctx
}

export default AuthProvider
