import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useLayoutEffect, useState } from 'react'
import { Menu } from 'react-pro-sidebar'
import { useLocation } from 'react-router-dom'
import NavItem from './NavItem'

const StyledMenu = styled(Menu)`
    width: 279px;

    ul {
        display: flex;
        flex-direction: column;
        gap: 8px;

        width: 100%;
        
        .ps-menuitem-root {
            .ps-menu-button {
                display: flex;
                align-items: center;
                padding: 0px 8px;
                gap: 13px;
            
                width: 100%;
                height: 48px;

                border-radius: 8px;

                .ps-menu-icon {
                    width: initial;
                    min-width: initial;
                    height: initial;
                    margin-right: initial;
                }
        
                .ps-menu-label {
                    font-size: 16px;
                    color: #8EB3BD;
                }    
                
                .ps-submenu-expand-icon > span {
                    width: 7px;
                    height: 7px;
                    color: #8EB3BD;                    
                }

                &:hover {
                    background: #09414F;
                }

                &.ps-active {
                    .ps-menu-icon > img {
                        filter: invert(50%) sepia(74%) saturate(445%) hue-rotate(108deg) brightness(94%) contrast(93%);
                    }
    
                    .ps-menu-label {
                        color: #FFFFFF;
                    }

                    &:hover {
                        background: none;
                    }                    
                }                
            }
        }  
        
        .ps-submenu-root {
            display: flex;
            flex-direction: column;
            gap: 18px;

            .ps-submenu-content {
                width: 259px;

                background: transparent;
                margin-left: 20px;
                padding-left: 19px;
                border-left: 1px solid #0D6075;

                .ps-menuitem-root {
                    .ps-menu-button {
                        .ps-menu-icon {
                            display: none;
                        }

                        &.ps-active {
                            background: #193B40;

                            .ps-menu-icon {
                                display: initial;
                            }
                        }
                    }
                }

                &.ps-open {
                    display: block;
                    overflow: auto;
                    height: auto;
                }                
            }            
        }
    }
`

const SidebarMenu = ({ items, isSubmenu = false }) => {
    const [ active, setActive ] = useState(null)
    const [ open, setOpen ] = useState(null)

    const activate = id => setActive(id)
    const expand = id => setOpen(open === id ? null : id)

    const location = useLocation()

    const currentPage = () => {
        for ( let res of items ) {
            if (res.children) {
                if ( res.children.find( item => item.path === location.pathname ) ) {
                    expand(res.id)
                }
            } else {
                if ( res.path === location.pathname ) {
                    activate(res.id)
                }
            }
        }        
    }    

    const navItems = items.map( item =>
        <NavItem
            key={item.id}
            active={active}
            activate={activate}
            open={open}
            expand={expand}
            isSubmenuItem={isSubmenu}
            {...item}
        />            
    )

    useLayoutEffect( () => {
        currentPage()
    }, [] )

    return isSubmenu ? navItems : <StyledMenu>{navItems}</StyledMenu>
}

SidebarMenu.propTypes = {
    items: PropTypes.array.isRequired,
    isSubmenu: PropTypes.bool
}

export default SidebarMenu
