/**
 * Utility object with keys to functions pertaining to local storage management.
 *
 * @see AuthProvider
 */
import Cookies from 'js-cookie'
import { parseJwt } from './string'
import { env } from './env'

const cookie = 'token'

export const storage = {
	setUser: (user) => {
		const {refreshToken, ...data} = user
		
		localStorage.setItem('user', JSON.stringify(data))

		const decodedJwt = parseJwt(refreshToken)
		const expiry = new Date(decodedJwt.exp * 1000)
		
		Cookies.set(cookie, refreshToken, {
			expires: expiry,
			secure: env('SECURE_COOKIE_TRANSMISSION', true),
		})
	},
	
	getUser: () => {
		const user = JSON.parse(localStorage.getItem('user'))
		return user ? {...user, refreshToken: Cookies.get(cookie)} : null
	},

	clearUser: () => {
		localStorage.removeItem('user')
		Cookies.remove(cookie)
	},
	
	setLogoutEvent: () => window.localStorage.setItem( 'app_logout', JSON.stringify(Date.now()) ),
}
