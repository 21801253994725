import { Component } from 'react'
import Error from './Error'

export default class ErrorBoundary extends Component {

	state = { hasError: false }


	componentDidCatch ( error, info ) {
		this.setState({ hasError: true })
	}

	render() {
		return (this.state.hasError) ? <Error /> : this.props.children
	}

}
