import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Master from './Master'

const buttons = {
    'ghost-dark': styled(Master)`
        background: none;
    `,

    'ghost-lite': styled(Master)`
        background: none;
        color: #193B40;
        padding-left: 0;
        padding-right: 0;
        
        svg path {
            fill: #193B40;
        } 
    `,

    'fade-dark': styled(Master)`
        background: #162C33;
    `,

    fade: styled(Master)`
        background: #F0F5F3;
        color: #193B40;

        svg path {
            fill: #193B40;
        } 
    `,

    outline: styled(Master)`
        background: none;
        border: 1px solid #EBEBEB;
        color: #193B40;
        
        svg path {
            fill: #193B40;
        }      
    `,

    secondary: styled(Master)`
        background: #09414F;
    `,

    primary: styled(Master)`
        background: #1AAB7A;
    `
}

function Button({size, children, ...props}) {
    const Btn = buttons[props.type]

    return (
        <Btn property={size+''} {...props}>
            {children}
        </Btn>
    )
}

Button.defaultProps = {
    elementType: 'button',
    iconRight: false,
    iconLeft: false,
    type: 'primary',
    size: '58',
}

Button.propTypes = {
    elementType: PropTypes.oneOf(['button', 'a']),
    iconRight: PropTypes.bool,
    rightIcon: PropTypes.object,
    iconLeft: PropTypes.bool,
    leftIcon: PropTypes.object,
	type: PropTypes.oneOf(Object.keys(buttons)),
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    className: PropTypes.string,
}

export default Button
