import PropTypes from 'prop-types'
import Button from '../Common/Button/Button'
import { diff } from '../../utils/date'


export const Subscription = ({subscription: {validity, plan, trial}}) => {
    const remainingDays = Math.round(diff(new Date, validity))

    return (
        <Button size={38} type='outline' elementType='a' to='/billing'>
            {plan.name} Plan - {trial ? 'Trial' : ''} {remainingDays} days left
        </Button>
    )
}

Subscription.propTypes = {
    subscription: PropTypes.object.isRequired,
}
